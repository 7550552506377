// dependencies imports ⇒
import React, { useEffect, useState } from "react";
// assets and scss imports ⇒
import "./abhaMobileLinkView.scss";
import Flag from "../../../assets/Flags.png";
import TickGif from "../../../assets/tickGif.gif";
import editIcon from "../../../assets/edit_icon.svg";
// project file imports(UI components) ⇒
import AbhaActionButton from "../common/AbhaActionButton";
import AbhaOTPInput from "../common/AbhaOTPInput";
import AbhaCardView from "../common/AbhaCardView";
// const file imports(helper methods, etc) ⇒
import {
  linkMobileNumber,
  resendOtpToLinkMobileNumber,
  sendOtpToLinkMobileNumber,
} from "../AbhaAPIHandler";
import {
  LINK_MOBILE_NUMBER,
  LINK_MOBILE_NUMBER_OTP,
  LINK_MOBILE_NUMBER_RESEND_OTP,
} from "../AbhaAPIHandler/APIConstants";
import { OTP_VALIDATION_ERROR } from "../constant";

const AbhaMobileLinkView = (props) => {
  const {
    transactionID,
    enteredMobileNumber,
    handleSteps,
    handleAbhaNumber,
    handlePatientId,
  } = props;
  // const declarations ⇒
  const [mobileNumber, setMobileNumber] = useState(enteredMobileNumber);
  const [disabledButton, setDisableButton] = useState(true);
  const [showOTPInput, setShowOTPInput] = useState(true);
  const [mobileInputError, setMobileInputError] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [isEdited, setIsEdited] = useState(false);
  const [resetTimer, setResetTimer] = useState(false);

  // life cycle methods ⇒
  useEffect(() => {
    if (mobileNumber?.length === 10 && otp?.length === 6) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [mobileNumber, otp]);

  // API Calls ⇒
  const linkMobileNumberAPICall = (reqPayload) => {
    const linkMobileNumberResponse = linkMobileNumber(
      LINK_MOBILE_NUMBER,
      reqPayload
    ); //Fetch API Data
    linkMobileNumberResponse.then(([res, error]) => {
      if (!error) {
        if (res?.data?.statusCode == 200) {
          setMobileInputError(false);
          setShowOTPInput(true);
        } else {
          setMobileInputError(true);
        }
      } else {
        setMobileInputError(true);
      }
    });
  };

  const sendOtpToLinkMobileNumberAPICall = (reqPayload) => {
    const sendOtpResponse = sendOtpToLinkMobileNumber(
      LINK_MOBILE_NUMBER_OTP,
      reqPayload
    ); //Fetch API Data
    sendOtpResponse.then(([res, error]) => {
      if (!error) {
        if (res?.data?.statusCode == 200) {
          setDisableButton(false);
          setOtpError(false);
          handlePatientId(res?.data?.patient_id);
          if (res?.data?.accounts?.length > 0) {
            handleAbhaNumber(res?.data?.accounts[0]?.ABHANumber);
          }

          handleSteps(4);
        } else {
          setErrorMessage(
            res?.data?.code
              ? res?.data?.code?.toLowerCase() == "abdm-1100"
                ? OTP_VALIDATION_ERROR?.THIRD_ERROR
                : OTP_VALIDATION_ERROR?.SECOND_ERROR
              : OTP_VALIDATION_ERROR?.FIRST_ERROR
          );
          setOtpError(true);
          setDisableButton(false);
        }
      } else {
        setErrorMessage(OTP_VALIDATION_ERROR.SECOND_ERROR);
        setOtpError(true);
        setDisableButton(false);
      }
    });
  };

  const resendOtpToLinkMobileNumberAPICall = (reqPayload) => {
    const linkMobileNumberResponse = resendOtpToLinkMobileNumber(
      LINK_MOBILE_NUMBER_RESEND_OTP,
      reqPayload
    ); //Fetch API Data
    linkMobileNumberResponse.then(([res, error]) => {
      if (!error) {
        if (res?.data?.statusCode == 200) {
          console.log("res", res);
        } else {
          setErrorMessage(
            res?.data?.code?.toLowerCase() == "abdm-1100"
              ? OTP_VALIDATION_ERROR?.THIRD_ERROR
              : OTP_VALIDATION_ERROR?.SECOND_ERROR
          );
          setMobileInputError(true);
        }
      }
    });
  };

  const handleVerifyMobileNumber = () => {
    setDisableButton(true);
    let otp_payload = {
      otp: otp,
      txnid: transactionID,
    };
    sendOtpToLinkMobileNumberAPICall(otp_payload);
  };

  const handleGetOTP = () => {
    if (mobileNumber?.length === 10) {
      let payload = {
        mobile_number: mobileNumber,
        txnid: transactionID,
      };
      setResetTimer(true);
      setMobileInputError(false);
      setIsEdited(false);
      linkMobileNumberAPICall(payload);
    }
  };

  // Actions ⇒
  const handleMobileNumber = (value) => {
    if (value?.length <= 10) {
      setMobileNumber(value);
    }
  };

  const handleKeyPress = (e) => {
    (e.key?.toLowerCase() === "e" ||
      e.key === "+" ||
      e.key === "-" ||
      e.key === "." ||
      e.key === "ArrowUp" ||
      e.key === "ArrowDown") &&
      e.preventDefault();
  };

  const handleResendOTP = () => {
    let requestPayload = {
      mobile_number: mobileNumber,
      txnid: transactionID,
    };
    resendOtpToLinkMobileNumberAPICall(requestPayload);
  };

  const handleOnChange = (otpInput) => {
    setOtp(otpInput);
  };

  const handleEditMobileNumber = () => {
    setIsEdited(true);
  };

  const handleResetTimer = () => {
    setResetTimer(false);
  };

  // create UI ⇒
  const abhaAadharNumberVerifiedUI = () => {
    return (
      <div className="amlv-aadhar-verified-container">
        <div className="amlv-aadhar-verified-content">
          <div className="amlv-aadhar-verified-icon">
            <img className="amlv-card-tick-gif" src={TickGif} alt="" />
          </div>
          <div className="amlv-aadhar-verified-text">
            Your Aadhaar Number Is Verified
          </div>
        </div>
      </div>
    );
  };

  const abhaMobileInputUI = () => {
    return (
      <div className="amlv-mobile-container">
        <div className="amlv-mobile-input-label">
          Link Your Mobile Number With This ABHA
        </div>

        {!isEdited ? (
          <div className="amlv-mobile-number-text-container">
            <div className="amlv-mobile-number-text">
              Your Mobile Number{" "}
              <span className="amlv-mobile-number">{mobileNumber}</span>
            </div>
            <div>
              <img
                src={editIcon}
                alt=""
                onClick={handleEditMobileNumber}
                className="amlv-edit-icon"
              />
            </div>
          </div>
        ) : (
          <>
            <div className="amlv-mobile-input-container">
              <div
                className={
                  "amlv-mobile-input-content " +
                  (mobileInputError ? "amlv-mobile-input-content-error" : "")
                }
              >
                <div className="amlv-mobile-input-prefix">
                  <img
                    src={Flag}
                    alt="flag"
                    className="amlv-mobile-input-prefix-image"
                  />
                  +91
                </div>
                <div className="amlv-mobile-input">
                  <input
                    type="number"
                    value={mobileNumber}
                    onChange={(e) => handleMobileNumber(e.target.value)}
                    onKeyDown={handleKeyPress}
                  />
                </div>
                <div className={"amlv-get-otp-button"} onClick={handleGetOTP}>
                  <div
                    className={
                      "amlv-get-otp-button-text " +
                      (mobileNumber?.length !== 10
                        ? "amlv-get-otp-button-text-disabled"
                        : "")
                    }
                  >
                    Get OTP
                  </div>
                </div>
              </div>
              {mobileInputError && (
                <div className="amlv-mobile-input-error">
                  Please enter valid mobile number
                </div>
              )}
            </div>
            <div className="amlv-mobile-content-text">
              This mobile number will be used for all the communications related
              to ABHA.
            </div>
          </>
        )}
      </div>
    );
  };

  const abhaMobileOtpUI = () => {
    return (
      <div className="amlv-otp-container">
        <AbhaOTPInput
          autoFocus
          length={6}
          otpError={otpError}
          errorMessage={errorMessage}
          placeholder="*"
          resetTimer={resetTimer}
          onChangeOTP={handleOnChange}
          handleResendOTP={handleResendOTP}
          handleResetTimer={handleResetTimer}
        />
      </div>
    );
  };

  const abhaMobileLinkButtonUI = () => {
    return (
      <div className="amlv-mobile-link-button-container">
        <AbhaActionButton
          title={"Verify Mobile Number"}
          disabled={disabledButton}
          handleClick={handleVerifyMobileNumber}
        />
      </div>
    );
  };

  const abhaLinkMobileHeading = () => {
    return (
      <div className="amlv-link-mobile-heading">
        Create ABHA (Health ID) Card
      </div>
    );
  };

  const abhaMobileLinkViewUI = () => {
    return (
      <div>
        <AbhaCardView>
          {abhaLinkMobileHeading()}
          {abhaAadharNumberVerifiedUI()}
          {abhaMobileInputUI()}
          {showOTPInput ? abhaMobileOtpUI() : null}
          {abhaMobileLinkButtonUI()}
        </AbhaCardView>
      </div>
    );
  };

  return <div className="abha-mobile-link-view">{abhaMobileLinkViewUI()}</div>;
};

//export
export default AbhaMobileLinkView;
