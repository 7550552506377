import calendarIcon from "../../../assets/calendar.svg";
import FilterIcon from "../../../assets/newIcn.png";
import { dateFilter } from "../../DashboardAppointmentsView/Constant";

export const PROCEDURE_SLOTS = [
  {
    id: 1,
    value: 2,
    label: "2 Mins",
    slotType: 7,
  },
  {
    id: 2,
    value: 3,
    label: "3 Mins",
    slotType: 6,
  },
  {
    id: 3,
    value: 4,
    label: "4 Mins",
    slotType: 4,
  },
  {
    id: 4,
    value: 5,
    label: "5 Mins",
    slotType: 5,
  },
  {
    id: 5,
    value: 10,
    label: "10 Mins",
    slotType: 10,
  },
  {
    id: 6,
    value: 15,
    label: "15 Mins",
    slotType: 0,
  },
  {
    id: 7,
    value: 20,
    label: "20 Mins",
    slotType: 20,
  },
  {
    id: 8,
    value: 25,
    label: "25 Mins",
    slotType: 25,
  },
  {
    id: 9,
    value: 30,
    label: "30 Mins",
    slotType: 1,
  },
  {
    id: 10,
    value: 45,
    label: "45 Mins",
    slotType: 2,
  },
  {
    id: 11,
    value: 60,
    label: "1 Hour",
    slotType: 3,
  },
];

export const DAYS = [
  {
    id: 0,
    value: "0",
    label: "Mon",
  },
  {
    id: 1,
    value: "1",
    label: "Tue",
  },
  {
    id: 2,
    value: "2",
    label: "Wed",
  },
  {
    id: 3,
    value: "3",
    label: "Thu",
  },
  {
    id: 4,
    value: "4",
    label: "Fri",
  },
  {
    id: 5,
    value: "5",
    label: "Sat",
  },
  {
    id: 6,
    value: "6",
    label: "Sun",
  },
];

export const CONSULT_TABS = [
  { id: 0, name: "Regular", icon: "fa fa-user-md" },
  { id: 1, name: "Follow-up", icon: "fa fa-hospital-o" },
  { id: 2, name: "AddNewProcedure", icon: "fa fa-hospital-o" },
];
export const UPDATE_SCHEDULE = "UPDATE_SCHEDULE";
export const VIEW_CALENDAR = "VIEW_CALENDAR";
export const MY_VACATIONS = "MY_VACATIONS";
export const BULK_RESCHEDULE = "BULK_RESCHEDULE";
const VIEW_LOGS_ROLE_MENU_DATA = [
  {
    name: "All",
    value: "all",
    selected: false,
  },
  {
    name: "Doctor",
    value: "doctor",
    selected: false,
  },
  {
    name: "Staff",
    value: "staff",
    selected: false,
  },
];
const VIEW_LOGS_ACTION_MENU_ITEMS = [
  {
    name: "All",
    value: "all",
    selected: false,
  },
  {
    name: "Consultation Duration",
    value: "consultation_duration",
    selected: false,
  },
  {
    name: "Consultation Fees",
    value: "consultation_fees",
    selected: false,
  },
  {
    name: "Consultation Type",
    value: "consultation_type",
    selected: false,
  },
  {
    name: "Discount",
    value: "discount",
    selected: false,
  },
  {
    name: "Instruction",
    value: "instruction",
    selected: false,
  },
  {
    name: "New Slot",
    value: "new_slot",
    selected: false,
  },
  {
    name: "Delete Slot",
    value: "delete_slot",
    selected: false,
  },
  {
    name: "Procedure Name",
    value: "procedure_name",
    selected: false,
  },
];
const VIEW_LOGS_CONSULTATION_DEFAULT_MENU = [
  {
    name: "All",
    value: "all",
    selected: false,
  },
  {
    name: "Tele-Consult Regular",
    value: "online-regular",
    selected: false,
  },
  {
    name: "Tele-Consult Follow-Up",
    value: "online-followup",
    selected: false,
  },
  {
    name: "In-Clinic Regular",
    value: "inclinic-regular",
    selected: false,
  },
  {
    name: "In-Clinic Follow-Up",
    value: "inclinic-followup",
    selected: false,
  },
  {
    name: "Instant",
    value: "instant",
    selected: false,
  },
];
export const VIEW_LOGS_MAP = {
  UPDATE_SCHEDULE: {
    HEADERS: [
      {
        id: 1,
        title: "DATE & TIME",
        mobileFilterTitle: "DATE & TIME",
        filter_type: "date",
        menuData: [...dateFilter],
        tempMenu: [...dateFilter],
        filter_icon: calendarIcon,
        show_filter: false,
        dontShowOnMobile: false,
      },
      {
        id: 2,
        title: "CONSULTATION",
        mobileFilterTitle: "CONSULTATION",
        filter_type: "normal",
        menuData: [],
        tempMenu: [],
        filter_icon: FilterIcon,
        show_filter: false,
        show_search: true,
        dontShowOnMobile: false,
        show_filter_searchbar: true,
        placeholder: "Search for procedure",
      },
      {
        id: 3,
        title: "ACTION",
        mobileFilterTitle: "ACTION",
        filter_type: "normal",
        menuData: [...VIEW_LOGS_ACTION_MENU_ITEMS],
        tempMenu: [...VIEW_LOGS_ACTION_MENU_ITEMS],
        filter_icon: FilterIcon,
        show_filter: false,
        show_search: true,
        dontShowOnMobile: false,
        show_filter_searchbar: true,
        placeholder: "Search for field",
      },
      {
        id: 4,
        title: "ROLE",
        mobileFilterTitle: "ROLE",
        filter_type: "normal",
        menuData: [...VIEW_LOGS_ROLE_MENU_DATA],
        tempMenu: [...VIEW_LOGS_ROLE_MENU_DATA],
        filter_icon: FilterIcon,
        show_filter: false,
        dontShowOnMobile: false,
      },
      {
        id: 5,
        title: "ROLE NAME",
        mobileFilterTitle: "ROLE NAME",
        filter_type: "normal",
        menuData: [],
        tempMenu: [],
        filter_icon: FilterIcon,
        show_filter: false,
        show_search: true,
        dontShowOnMobile: false,
        show_filter_searchbar: true,
        placeholder: "Search person",
      },
    ],
  },
  VIEW_CALENDAR: {
    HEADERS: [
      {
        id: 1,
        title: "DATE & TIME",
        mobileFilterTitle: "DATE & TIME",
        filter_type: "date",
        menuData: [...dateFilter],
        tempMenu: [...dateFilter],
        filter_icon: calendarIcon,
        show_filter: false,
        dontShowOnMobile: false,
      },
      {
        id: 2,
        title: "CONSULTATION",
        mobileFilterTitle: "CONSULTATION",
        filter_type: "normal",
        menuData: [],
        tempMenu: [],
        filter_icon: FilterIcon,
        show_filter: false,
        show_search: true,
        dontShowOnMobile: false,
        show_filter_searchbar: true,
        placeholder: "Search for procedure",
      },
      {
        id: 3,
        title: "ACTION",
        mobileFilterTitle: "ACTION",
        filter_type: "normal",
        menuData: [],
        tempMenu: [],
        show_filter: false,
        dontShowOnMobile: true,
      },
      {
        id: 4,
        title: "ROLE",
        mobileFilterTitle: "ROLE",
        filter_type: "normal",
        menuData: [...VIEW_LOGS_ROLE_MENU_DATA],
        tempMenu: [...VIEW_LOGS_ROLE_MENU_DATA],
        filter_icon: FilterIcon,
        show_filter: false,
        dontShowOnMobile: false,
      },
      {
        id: 5,
        title: "ROLE NAME",
        mobileFilterTitle: "ROLE NAME",
        filter_type: "normal",
        menuData: [],
        tempMenu: [],
        filter_icon: FilterIcon,
        show_filter: false,
        show_search: true,
        dontShowOnMobile: false,
        show_filter_searchbar: true,
        placeholder: "Search person",
      },
    ],
  },
  MY_VACATIONS: {
    HEADERS: [
      {
        id: 1,
        title: "DATE & TIME",
        mobileFilterTitle: "DATE & TIME",
        filter_type: "date",
        menuData: [...dateFilter],
        tempMenu: [...dateFilter],
        filter_icon: calendarIcon,
        show_filter: false,
        dontShowOnMobile: false,
      },
      {
        id: 2,
        title: "CONSULTATION",
        mobileFilterTitle: "CONSULTATION",
        filter_type: "normal",
        menuData: [],
        tempMenu: [],
        filter_icon: FilterIcon,
        show_filter: false,
        show_search: true,
        dontShowOnMobile: false,
        show_filter_searchbar: true,
        placeholder: "Search for procedure",
      },
      {
        id: 3,
        title: "ACTION",
        mobileFilterTitle: "ACTION",
        filter_type: "normal",
        menuData: [],
        tempMenu: [],
        show_filter: false,
        dontShowOnMobile: true,
      },
      {
        id: 4,
        title: "ROLE",
        mobileFilterTitle: "ROLE",
        filter_type: "normal",
        menuData: [...VIEW_LOGS_ROLE_MENU_DATA],
        tempMenu: [...VIEW_LOGS_ROLE_MENU_DATA],
        filter_icon: FilterIcon,
        show_filter: false,
        dontShowOnMobile: false,
      },
      {
        id: 5,
        title: "ROLE NAME",
        mobileFilterTitle: "ROLE NAME",
        filter_type: "normal",
        menuData: [],
        tempMenu: [],
        filter_icon: FilterIcon,
        show_filter: false,
        show_search: true,
        dontShowOnMobile: false,
        show_filter_searchbar: true,
        placeholder: "Search person",
      },
    ],
  },
  BULK_RESCHEDULE: {
    HEADERS: [
      {
        id: 1,
        title: "DATE & TIME",
        mobileFilterTitle: "DATE & TIME",
        filter_type: "date",
        menuData: [...dateFilter],
        tempMenu: [...dateFilter],
        filter_icon: calendarIcon,
        show_filter: false,
        dontShowOnMobile: false,
      },
      {
        id: 2,
        title: "ACTION",
        mobileFilterTitle: "ACTION",
        filter_type: "normal",
        menuData: [],
        tempMenu: [],
        show_filter: false,
        dontShowOnMobile: true,
      },
      {
        id: 3,
        title: "ROLE",
        mobileFilterTitle: "ROLE",
        filter_type: "normal",
        menuData: [...VIEW_LOGS_ROLE_MENU_DATA],
        tempMenu: [...VIEW_LOGS_ROLE_MENU_DATA],
        filter_icon: FilterIcon,
        show_filter: false,
        dontShowOnMobile: false,
      },
      {
        id: 4,
        title: "ROLE NAME",
        mobileFilterTitle: "ROLE NAME",
        filter_type: "normal",
        menuData: [],
        tempMenu: [],
        filter_icon: FilterIcon,
        show_filter: false,
        show_search: true,
        dontShowOnMobile: false,
        show_filter_searchbar: true,
        placeholder: "Search person",
      },
    ],
  },
};

export const DELAY_MARKING_RADIO_BTN = "delay_marking";
export const MY_VACATIONS_RADIO_BTN = "my_vacations";

export const MY_SCHEDULE_DAYS = [
  {
    id: "7",
    name: "Sun",
    value: "0",
    checked: false,
    disabled: false,
  },
  {
    id: "1",
    name: "Mon",
    value: "1",
    checked: false,
    disabled: false,
  },
  {
    id: "2",
    name: "Tue",
    value: "2",
    checked: false,
    disabled: false,
  },
  {
    id: "3",
    name: "Wed",
    value: "3",
    checked: false,
    disabled: false,
  },
  {
    id: "4",
    name: "Thu",
    value: "4",
    checked: true,
    disabled: false,
  },
  {
    id: "5",
    name: "Fri",
    value: "5",
    checked: false,
    disabled: false,
  },
  {
    id: "6",
    name: "Sat",
    value: "6",
    checked: false,
    disabled: false,
  },
];

export const ARROW_TYPE = {
  ARROW_UP: "up",
  ARROW_DOWN: "down",
};

export const DAYS_OF_WEEK = {
  0: "Sun",
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
};
