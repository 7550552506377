import React, { useEffect, useState } from "react";
import Menu from "material-ui-popup-state/HoverMenu";
import MenuItem from "@material-ui/core/MenuItem";
import {
  usePopupState,
  bindHover,
  bindMenu,
} from "material-ui-popup-state/hooks";
import { Link } from "react-router-dom";
import "./styles/MyAccountAndLoginButton.scss";
import Arrowdown from "../../assets/forhomepage/arrowdown-newhomepage.svg";
import { LOCALSTORAGEKEY } from "../../Util/Helper/localStorageUtils";
import { DEV, QA, UAT } from "../common/config";

const MenuPopupState = (props) => {
  const popupState = usePopupState({ variant: "popover", popupId: "demoMenu" });
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const [userType, setUserType] = useState(null);
  let staffClinicAssociation = localStorage.getItem(
    LOCALSTORAGEKEY.STAFF_CLINIC_ASSOCIATION
  );
  let doctorClinicAssociation = localStorage.getItem(
    LOCALSTORAGEKEY.DOCTOR_CLINIC_ASSOCIATION
  );
  useEffect(() => {
    if (!window.localStorage.getItem("user_type")) {
      setUserType(null);
    } else {
      if (
        staffClinicAssociation === "true" ||
        doctorClinicAssociation === "true"
      ) {
        setUserType("clinic");
      } else {
        if (window.localStorage.getItem("user_type") === "staff") {
          setUserType("doctor");
        } else {
          setUserType(window.localStorage.getItem("user_type"));
        }
      }
    }
  }, [window.localStorage]);

  if (
    userType !== "patient" &&
    userType !== "doctor" &&
    userType !== "staff" &&
    userType !== "clinic"
  ) {
    return (
      <React.Fragment>
        <div
          className={
            props.isComingFromHeader !== undefined && props.isComingFromHeader
              ? "menu-custom-button-Header wrapper-login"
              : "menu-custom-button wrapper-login"
          }
          {...bindHover(popupState)}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="right-menu">Login</div>
          {props.isComingFromHeader !== undefined &&
          props.isComingFromHeader ? (
            <img
              src={require("../../assets/arrow-down.png")}
              alt="#"
              style={{
                transform: isHovered ? "rotate(-180deg)" : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          ) : (
            <img
              className="arrowdown-mob"
              src={Arrowdown}
              alt="#"
              style={{
                transform: isHovered ? "rotate(-180deg)" : "rotate(0deg)",
                transition: "transform 0.3s",
              }}
            />
          )}
        </div>

        <Menu
          {...bindMenu(popupState)}
          getContentAnchorEl={null}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          open={isHovered}
        >
          <MenuItem onClick={popupState.close}>
            <Link className="thecolor" to={`/doctor/login`}>
              Doctor Login
            </Link>
          </MenuItem>
          <MenuItem onClick={popupState.close}>
            <Link
              className="thecolor"
              onClick={() => localStorage.setItem("nav_item_doctor", 2)}
              to={`/patient/login`}
            >
              Patient Login
            </Link>
          </MenuItem>
          {(process.env.REACT_APP_ENV === DEV ||
            process.env.REACT_APP_ENV === QA ||
            process.env.REACT_APP_ENV === UAT ||
            window.location.hostname === "localhost") && (
            <MenuItem onClick={popupState.close}>
              <Link
                className="thecolor"
                // onClick={() => localStorage.setItem("nav_item_doctor", 2)}
                to={`/pharmacist/login`}
              >
                Pharmacist Login
              </Link>
            </MenuItem>
          )}
        </Menu>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div
        className={
          props.isComingFromHeader !== undefined && props.isComingFromHeader
            ? "menu-custom-button-Header wrapper-login"
            : "menu-custom-button wrapper-login"
        }
        {...bindHover(popupState)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="right-menu">My Account</div>

        {props.isComingFromHeader !== undefined && props.isComingFromHeader ? (
          <img
            src={require("../../assets/arrow-down.png")}
            alt="#"
            style={{
              transform: isHovered ? "rotate(-180deg)" : "rotate(0deg)",
              transition: "transform 0.3s",
            }}
          />
        ) : (
          <img
            className="arrowdown-mob"
            src={Arrowdown}
            alt="#"
            style={{
              transform: isHovered ? "rotate(-180deg)" : "rotate(0deg)",
              transition: "transform 0.3s",
            }}
          />
        )}
      </div>

      <Menu
        {...bindMenu(popupState)}
        getContentAnchorEl={null}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        open={isHovered}
      >
        <MenuItem onClick={popupState.close}>
          <Link className="thecolor" to={`/${userType}/account/dashboard`}>
            Dashboard
          </Link>
        </MenuItem>
        {userType === "patient" && (
          <MenuItem onClick={popupState.close}>
            <Link
              className="thecolor"
              onClick={() => localStorage.setItem("nav_item_doctor", 2)}
              to={{
                pathname: `/${userType}/account/appointmenthistory`,
                state: { previousCheckup: 2 },
              }}
            >
              Appointment History
            </Link>
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            popupState.close();
          }}
        >
          <Link className="thecolor" to={`/logout`}>
            Logout
          </Link>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default MenuPopupState;
