import React from "react";
import { useHistory } from "react-router-dom";

function Details({ summary, back, disableProceedBtn, proceed }) {
  let history = useHistory();
  return (
    <div className="payment-content">
      <div className="text-center pt-5 payment-title font-weight-bold">
        PAYMENT
      </div>
      <div className="payment-details mx-auto mt-3 py-4 px-5">
        <div>
          <div>
            Patient Name:{" "}
            <span className="font-weight-bold">{summary.patient_name}</span>
          </div>
          <div className="mt-3">
            Patient Email:{" "}
            <span className="font-weight-bold">{summary.patient_email}</span>
          </div>
        </div>
        <hr className="payment-margin" />
        <div>
          <div className="mt-3 d-flex justify-content-between">
            {+summary?.partial_amount > 0 ? (
              <>
                <div>Part Payment</div>
                <div className="font-weight-bold">
                  Rs {summary.partial_amount}
                </div>
              </>
            ) : (
              <>
                <div>
                  {summary?.account_type === 1
                    ? "Procedure Fee"
                    : `Dr.${
                        summary?.doctor_name?.split(" ")[1]
                      }'s Consultation Fee`}
                </div>
                <div className="font-weight-bold">
                  Rs {summary.consultation_fees}
                </div>
              </>
            )}
          </div>
          {summary?.total_discount > 0 && +summary?.partial_amount <= 0 && (
            <div
              className="mt-3 d-flex justify-content-between"
              style={{ color: "#10A74A" }}
            >
              <div>Total Discount</div>
              <div className="font-weight-bold">
                -Rs {summary.total_discount}
              </div>
            </div>
          )}
          <div className="mt-3 d-flex justify-content-between">
            <div>Convenience fees</div>
            <div className="font-weight-bold">
              Rs {summary.convenience_fees}
            </div>
          </div>
          <div className="mt-3 d-flex justify-content-between">
            <div>GST</div>
            <div className="font-weight-bold">
              Rs {summary.gst_on_convenience_fee}
            </div>
          </div>
        </div>
        <hr className="payment-margin" />
        <div className="d-flex justify-content-between">
          <div>Total Fee</div>{" "}
          <div className="font-weight-bold">Rs {summary.total_fees}</div>
        </div>
        <hr className="payment-margin" />
        <div className="text-center mt-4">
          <button
            className="payment-back-btn py-2 px-4 mr-2"
            onClick={() => history.push("/patient/account/dashboard")}
          >
            Back
          </button>
          <button
            className={
              "payment-proceed-btn py-2 px-4 ml-2 " +
              (disableProceedBtn ? "payment-proceed-disabled-btn" : "")
            }
            onClick={() => proceed()}
            disabled={disableProceedBtn}
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
}

export default Details;
