import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import "./Finance.scss";
import afterLoginApiAxios from "../../Util/API Calls/afterLogin";
import { C2CConsole } from "../../Util/Helper/C2CConsole";

class Finance extends Component {
  constructor(props, context) {
    super(props, context);

    this.viewAppointment = this.viewAppointment.bind(this);
    this.closeAppointment = this.closeAppointment.bind(this);
    this.multiply = this.multiply.bind(this);
    this.currentMonth = this.currentMonth.bind(this);

    this.openForm = this.openForm.bind(this);
    this.closeForm = this.closeForm.bind(this);
    this.state = {
      docType: "",
      doctor: [],
      appointments: null,
      openView: false,
      form: false,
      patient: null,
      months: [
        "-",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    };
  }

  openForm() {
    this.setState({
      form: true,
    });
  }

  closeForm() {
    this.setState({
      form: false,
    });
  }

  componentWillMount() {
    if (localStorage.getItem("id_token")) {
    } else {
      this.props.history.push("/doctor/login");
    }

    let token = localStorage.getItem("id_token");
    let user_type = localStorage.getItem("user_type");
    let full_name = localStorage.getItem("full_name");

    this.setState({
      token: token,
      user_type: user_type,
      user_name: full_name,
      currentMonth: "",
      currentMonthEarnings: 0,
    });

    afterLoginApiAxios
      .post(
        "/doctor/get-my-profile",
        {},
        {
          headers: {
            Authorization: localStorage.getItem("id_token"),
          },
        }
      )
      .then((response) => {
        if (response) {
          let responseData = response.data;
          if (responseData) {
            if (response.data.hasOwnProperty("statusCode")) {
              if (response.data.statusCode == 200) {
                this.setState({
                  doctor: responseData.doctor_profile_info,
                });
              }
            } else {
              this.setState(
                {
                  response_message: "Something Went Wrong!Please Try Again!!",
                  dataload: false,
                },
                () => {}
              );
            }

            this.currentMonth();
          }
        }
      })
      .catch(function (error) {
        C2CConsole.log(error);
      });
  }

  viewAppointment(appoint) {
    this.setState({
      openView: true,
      patient: appoint,
    });
  }

  closeAppointment() {
    this.setState({
      openView: false,
    });
  }

  multiply(total_appointments) {
    return total_appointments * 600;
  }

  currentMonth() {
    let tempDate = new Date();
    let month = tempDate.getMonth();
    let earnings = 0;

    month = month + 1;

    let monthArray = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    if (this.state.appointments) {
      for (const i of this.state.appointments) {
        let appt_month = Number(
          this.state.appointments[i].appointment_date.substring(5, 7)
        );
        if (appt_month == month) {
          earnings = this.state.appointments.length * 600;
        }
      }
    }
    this.setState({
      currentMonth: monthArray[month - 1],
      currentMonthEarnings: earnings,
    });
  }

  render() {
    return (
      <div>
        <div
          className="container"
          style={{ backgroundColor: "#f5f6f700", padding: "20px" }}
        >
          <div className="row">
            <div className="col-sm-9 col-lg-9">
              <h4 className="finance-header-font finance-header-font-weight">
                MY ACCOUNT DETAILS
              </h4>
            </div>
            <div className="col-sm-2 col-lg-2">
              <button
                className="finance-back-button"
                onClick={this.props.showTransactionDetails}
              >
                Go Back
              </button>
            </div>
          </div>
          <div className="row mt-2 mb-3">
            <div className="col-sm-12 col-lg-12">
              <p className="finance-header-font">Account Summary</p>
            </div>
          </div>

          {this.state.doctor.bank_account_name &&
          this.state.doctor.bank_account_number &&
          this.state.doctor.bank_ifsc_code ? (
            <span>
              <div>
                <div
                  className="row goLiveCard p-2 m-1"
                  style={{ minHeight: "50px" }}
                >
                  <div className="col-sm-12 col-lg-5 pt-1 accountSummary">
                    <small className="smallTextColor">
                      Savings Account Number
                    </small>
                    <h3>
                      <strong>{this.state.doctor.bank_account_number}</strong>
                    </h3>
                    <p className="m-0">
                      Bank: {this.state.doctor.bank_account_name}
                    </p>
                    <small>
                      IFSC Code: {this.state.doctor.bank_ifsc_code}{" "}
                    </small>{" "}
                    &nbsp; &nbsp;
                  </div>
                  {/*
                <div className="col-sm-12 col-lg-2 pt-4">
                  <small className="smallTextColor">Total Earnings</small>
                  {this.state.appointments ? (
                    <h3>
                      <strong>
                        {this.multiply(this.state.appointments.length)}
                      </strong>
                    </h3>
                  ) : (
                    <h3>
                      <strong> 0.00 </strong>
                    </h3>
                  )}
                </div>
                <div className="col-sm-12 col-lg-2 pt-4">
                  <small className="smallTextColor">Account Balance</small>
                  {this.state.appointments ? (
                    <h3>
                      <strong>
                        {this.multiply(this.state.appointments.length) - 500}
                      </strong>
                    </h3>
                  ) : (
                    <h3>
                      <strong> 0.00 </strong>
                    </h3>
                  )}
                </div>
                */}
                  <div className="col-sm-12 col-lg-3 offset-lg-4 pt-4 requestAccountUpdate">
                    <button
                      className="btn btn-cn-inv btn-lg"
                      style={{ backgroundColor: "#17BAE0", color: "#fff" }}
                      onClick={() => {
                        // window.openWidget();
                      }}
                      type="button"
                    >
                      Request Update
                    </button>
                  </div>
                </div>
                {/*
                <div className="row p-2 m-3">
                  <div
                    className="col-sm-12 col-lg-4 accountsCard pt-5 m-3"
                    style={{ maxHeight: "220px" }}
                  >
                    <p className="smallTextColor p-0 m-0">
                      Current Month Earnings
                    </p>
                    <h1 style={{ color: "#fff", margin: "0px" }}>
                      {this.state.currentMonth}:{" "}
                      <strong>{this.state.currentMonthEarnings}</strong>
                      <small style={{ fontSize: "16px" }}> INR</small>
                    </h1>
                  </div>

                  <div
                    className="col-sm-12 col-lg-7 listCard pt-5 m-3"
                    style={{ minHeight: "220px", backgroundColor: "#ffffff00" }}
                  ></div>
                </div>
                */}
              </div>
            </span>
          ) : (
            <span className="text-center">No Data Found</span>
          )}

          <Modal show={this.state.form} onHide={this.closeForm}>
            <Modal.Header closeButton>
              <Modal.Title>
                <h4>Request Account Update</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <script src="https://desk.zoho.in/portal/api/feedbackwidget/25224000000075025?orgId=60004567725&displayType=iframe"></script>
              <iframe
                id="zsfeedbackFrame"
                width="100%"
                height="570"
                name="zsfeedbackFrame"
                scrolling="no"
                allowtransparency="false"
                frameBorder="0"
                border="0"
                src="https://desk.zoho.in/support/fbw?formType=AdvancedWebForm&fbwId=186e4a1159b801875a0f33adf0a2a637e612657a51fd5fbe&xnQsjsdp=vHySn*nxZF1DjW5bBGOqfQ$$&mode=showNewWidget&displayType=iframe"
              ></iframe>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="dark" onClick={this.closeForm}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}

export default Finance;
