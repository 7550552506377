import React, { useState, useEffect } from "react";
import Header from "../Header";
import CountdownTimer from "./CountdownTimer";
import Loader from "../UI Component/Loader/Loader";
import Details from "./Details";
import TimeSlotNotAvailableModal from "./TimeSlotNotAvailableModal";
import LinkExpiredModal from "./LinkExpiredModal";
import PatientLoginModal from "./PatientLoginModal";
import "./payment.scss";
import ReactGA from "react-ga";
import { Redirect, useParams } from "react-router";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import afterLoginApiAxios from "../../Util/API Calls/afterLogin";
import { DEV, QA, UAT } from "../common/config";
import { connect } from "react-redux";
import { LOCALSTORAGEKEY } from "../../Util/Helper/localStorageUtils";
import unAuthorizedError from "../Errors/unAuthorizedError";
import { C2CConsole } from "../../Util/Helper/C2CConsole";

function Payment(props) {
  const userId = localStorage.getItem(LOCALSTORAGEKEY.USER_ID);
  const [loader, setLoader] = useState(false);
  const [slotAvailable, setSlotAvailable] = useState(false);
  const [linkExpired, setLinkExpired] = useState(false);
  const [loggedInStatus, setLoggedInStatus] = useState(() => {
    try {
      if (
        localStorage.getItem("id_token") &&
        localStorage.getItem("user_type") == "patient"
      ) {
        return true;
      }
    } catch (error) {
      return false;
    }
    return false;
  });
  const [summary, setSummary] = useState({});
  const [hashVal, setHashVal] = useState({});
  const [disableProceedBtn, setDisableProceedBtn] = useState(true);

  const c2cEmail = localStorage.getItem("emailId");
  const c2cMobileNo = localStorage.getItem("mobileNumber");

  let history = useHistory();
  let { slug } = useParams();

  useEffect(() => {
    if (loggedInStatus) {
      summaryAction();
    }
  }, [loggedInStatus]);

  useEffect(() => {
    if (!c2cEmail && !c2cMobileNo) {
      getGlobalTemplates();
    }
  }, []);

  const updateTransactionResponseDetails = (response) => {
    if (response?.data?.statusCode === 200) {
      setLoader(false);
      setHashVal(response?.data?.RequestData);
      localStorage.setItem(
        LOCALSTORAGEKEY.TRANSACTION_ID,
        response.data.RequestData.txnid
      );
      localStorage.setItem(LOCALSTORAGEKEY.INCLINE_ONLINE_PAYMENT, true);
      localStorage.setItem(LOCALSTORAGEKEY.INCLINE_ONLINE_PAYMENT_ID, slug);
      setDisableProceedBtn(false);
    } else if (response.data.statusCode === 599) {
      setHashVal(response.data.RequestData);
      localStorage.setItem(
        LOCALSTORAGEKEY.TRANSACTION_ID,
        response.data.RequestData.txnid
      );
      localStorage.setItem(LOCALSTORAGEKEY.INCLINE_ONLINE_PAYMENT_ID, slug);
      localStorage.setItem(LOCALSTORAGEKEY.INCLINE_ONLINE_PAYMENT, true);
      localStorage.setItem(LOCALSTORAGEKEY.FREE_BOOKING, "free booking");
      localStorage.removeItem(LOCALSTORAGEKEY.CLINIC_ID);
      history.push("/thankyou");
    } else {
      setLoader(false);
      swal({
        title: "Error",
        text: response?.data?.message,
        type: "error",
        icon: "error",
      }).then(() => history.push("/patient/account/dashboard"));
    }
  };

  const getGlobalTemplates = () => {
    afterLoginApiAxios
      .get("/public/get-customer-support-details")
      .then(function (response) {
        props.dispatch({
          type: "UPDATE_MOBILE_NUMBER",
          payload: {
            mobileNumber: response.data.customer_support_no,
            emailId: response.data.customer_support_emailid,
          },
        });
        localStorage.setItem("mobileNumber", response.data.customer_support_no);
        localStorage.setItem("emailId", response.data.customer_support_emailid);
      })
      .catch(function (error) {
        C2CConsole.error(error);
        props.dispatch({
          type: "UPDATE_MOBILE_NUMBER",
          payload: {
            mobileNumber: "(033) 405 85600",
            emailId: "hello@connect2clinic.com",
          },
        });
      });
  };

  const summaryAction = async () => {
    let response = await afterLoginApiAxios({
      method: "post",
      url: "/patient/get-payment-summary-inclinic",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("id_token"),
      },
      data: { appointment_id: slug },
    });
    if (!response) {
      return unAuthorizedError(() => {
        history.push(window.location.pathname + "/login");
      });
    }
    if (response?.data?.statusCode === 200) {
      if (response?.data?.data?.account_type === 1) {
        localStorage.setItem(
          LOCALSTORAGEKEY.ORDER_ID,
          response?.data?.data?.order_id
        );
      }

      setSummary(response.data.data);
      if (response?.data?.data?.clinic_id) {
        localStorage.setItem(
          LOCALSTORAGEKEY.CLINIC_ID,
          response?.data?.data?.clinic_id
        );
      }
      if (response?.data?.data?.account_type === 1) {
        procedureHashKey();
      } else {
        generateHash();
      }
    } else if (response?.data?.statusCode === 509) {
      swal({
        title: "Already booked",
        text: response?.data?.message,
        type: "info",
        icon: "info",
      }).then(() => history.push("/patient/account/dashboard"));
    } else if (response?.data?.statusCode === 507) {
      setLinkExpired(true);
    } else {
      swal({
        title: "Error",
        text: response.data.message,
        type: "error",
        icon: "error",
      }).then(() => history.push("/patient/account/dashboard"));
    }
  };

  const generateHash = async () => {
    setLoader(true);
    const clinicId = localStorage.getItem(LOCALSTORAGEKEY.CLINIC_ID);
    let payload = { appointment_id: slug };
    if (clinicId !== "" && clinicId !== undefined && clinicId !== null) {
      payload["clinic_id"] = clinicId;
    }
    let response = await afterLoginApiAxios({
      method: "post",
      url:
        clinicId !== "" && clinicId !== undefined && clinicId !== null
          ? "/clinic/get-calculated-hash-inclinic"
          : "/public/get-calculated-hash-inclinic",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("id_token"),
      },
      data: payload,
    });
    updateTransactionResponseDetails(response);
  };

  const procedureHashKey = async () => {
    const orderID = localStorage.getItem(LOCALSTORAGEKEY.ORDER_ID);
    const clinicId = localStorage.getItem(LOCALSTORAGEKEY.CLINIC_ID);
    let payload = { order_id: orderID };
    if (clinicId !== "" && clinicId !== undefined && clinicId !== null) {
      payload["clinic_id"] = clinicId;
    }
    let response = await afterLoginApiAxios({
      method: "post",
      url:
        clinicId !== "" &&
        clinicId !== undefined &&
        clinicId !== null &&
        "/patient/patient-bluk-booking",
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("id_token"),
      },
      data: payload,
    });
    updateTransactionResponseDetails(response);
  };

  const proceed = () => {
    let razorpayID = "rzp_live_jTzrEHHIbpY2M3";
    if (
      process.env.REACT_APP_ENV === DEV ||
      process.env.REACT_APP_ENV === QA ||
      process.env.REACT_APP_ENV === UAT
    ) {
      razorpayID = "rzp_test_rKJ2mGfSEsNNP7";
    } else {
      razorpayID = ["prod"].includes(process.env.REACT_APP_ENV)
        ? "rzp_live_jTzrEHHIbpY2M3"
        : "rzp_live_qYyiASeyYtOJqb";
    }

    let options = {
      key: razorpayID,
      amount: Number(hashVal.amount) * 100, // 2000 paise = INR 20, amount in paisa
      name: "Connect2Clinic",
      description: "In-clinic Consultation",
      image: process.env.PUBLIC_URL + "/images/c2.png",
      order_id: hashVal.order_id,
      handler: function (response) {
        localStorage.setItem("paymentID", response.razorpay_payment_id);
        localStorage.setItem("orderID", response.razorpay_order_id);
        localStorage.setItem("signature", response.razorpay_signature);
        if (response.razorpay_payment_id && response.razorpay_order_id) {
          let gaLabel = response.razorpay_payment_id + ": " + userId;
          ReactGA.event({
            category: "razorpay",
            action: response.razorpay_order_id,
            label: gaLabel,
          });
          localStorage.removeItem(LOCALSTORAGEKEY.CLINIC_ID);
          history.push("/thankyou");
        } else {
          let gaLabel = hashVal.order_id + ": " + userId;
          ReactGA.event({
            category: "razorpay",
            action: "failed",
            label: gaLabel,
          });
          localStorage.removeItem(LOCALSTORAGEKEY.CLINIC_ID);
          history.push("/failed");
        }
      },
      prefill: {
        name: summary.patient_name,
        contact: hashVal.phone,
        email: hashVal.email,
      },
      theme: {
        color: "#17BAE0",
      },
    };
    let rzp = new window.Razorpay(options);
    rzp.open();

    const modalDocument = document.querySelector("#modal.mchild");
    if (modalDocument) {
      modalDocument.style.top = "60px";
    }
  };

  if (loggedInStatus) {
    return (
      <>
        {loader ? <Loader /> : null}
        <Header type="patient" />
        <div>
          <CountdownTimer
            doSomething={() => {
              history.push("/failed");
            }}
          />
        </div>
        <div className="container-fluid payment-wrap">
          <Details
            summary={summary}
            disableProceedBtn={disableProceedBtn}
            proceed={() => proceed()}
          />
        </div>
        <TimeSlotNotAvailableModal
          slotAvailable={slotAvailable}
          setSlotAvailable={() => setSlotAvailable(false)}
        />
        <LinkExpiredModal
          linkExpired={linkExpired}
          setLinkExpired={() => {
            history.push("/patient/account/dashboard");
            setLinkExpired(false);
          }}
        />
      </>
    );
  } else {
    return (
      <Redirect
        to={{
          pathname: `/in-clinic-payment/${slug}/login`,
        }}
      />
    );
  }
}

export const LoginPage = () => {
  const [showPatientLoginModal, setShowPatientLoginModal] = useState(true);
  return (
    <PatientLoginModal
      baseUrl="/in-clinic-payment/:slug"
      showPatientLoginModal={showPatientLoginModal}
      setShowPatientLoginModal={(value) => {
        setShowPatientLoginModal(value);
      }}
    />
  );
};

export function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default connect("", mapDispatchToProps)(Payment);
