import React, { useEffect, useState } from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import closeIcon from "../../../assets/close_icon.svg";

import afterLoginApiAxiosPEM from "../PEMAfterLogin";

const AddContentSearchBar = (props) => {
  const { placeholderContent } = props;
  const [inputText, setInputText] = useState("");
  const [backBtnEnable, setbackBtnEnable] = useState(true);

  // API Calls ==>

  // Fetching all search data ==>
  const allSearchContentAPICall = async (text) => {
    const res = await afterLoginApiAxiosPEM({
      method: "get",
      url: "search/all-articles",

      params: {
        specialization_id: localStorage.getItem("specialization"),
        query: text,
      },
    });

    return res;
  };

  // Fetching all articles list ==>
  const fetchAllArticles = async (
    keyword,
    articlesPerPage,
    pagenumber,
    allArticles
  ) => {
    let keywordValue = keyword ? keyword : "All";
    let allArticlesValue = allArticles ? allArticles : false;
    let pagenumberValue = pagenumber ? pagenumber : 1;
    let articlesPerPageValue = articlesPerPage ? articlesPerPage : 10;
    const res = await afterLoginApiAxiosPEM({
      method: "get",
      url: "doctor/get-all-articles",
      params: {
        keyword: keywordValue,
        specialization_id: localStorage.getItem("specialization"),
        page_number: pagenumberValue,
        articles_per_page: articlesPerPageValue,
        all_articles: allArticlesValue,
      },
    });

    return res.data;
  };
  // <==

  const searchApicall = (searchString) => {
    return allSearchContentAPICall(searchString);
  };

  // <==
  // <==
  // Actions ==>
  const backBtnClick = async () => {
    if (backBtnEnable) {
      setbackBtnEnable(false);
      const res = await fetchAllArticles("All", 10, 1, true);
      if (res) {
        setbackBtnEnable(true);
      }
      if (res?.response) {
        props.backBtnCallback(res.response);
      }
    }
  };

  const onSubmit = async (event) => {
    const searchString = event["search_string"];
    setInputText(searchString);

    if (searchString.trim().length === 0) {
      return;
    }

    let response = await searchApicall(searchString);
    if (response?.data?.data) {
      props.searchDataCallback(response.data.data);
    }
  };

  const clearData = () => {
    setInputText("");
  };

  // <==

  return (
    <div style={{ position: "relative" }}>
      <div className="search-wrapper">
        <div className="input-holder">
          <input
            type="text"
            className="search-input"
            style={{ marginLeft: "-10px" }}
            onChange={(e) => onSubmit({ search_string: e.target.value })}
            value={inputText}
            placeholder={placeholderContent}
          />{" "}
          <div
            className="search-icon"
            style={{ marginLeft: "-15px" }}
            onClick={() => backBtnClick()}
          >
            <ArrowBackIcon />
          </div>
          {inputText !== "" && (
            <div className="close_search">
              <img src={closeIcon} onClick={clearData} alt="" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddContentSearchBar;
