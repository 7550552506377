// dependencies imports ⇒
import React, { useEffect, useState } from "react";
// assets and scss imports ⇒
import "./abhaLinkWithAddressView.scss";
import TickGif from "../../../assets/tickGif.gif";
// project file imports(UI components) ⇒
import AbhaActionButton from "../common/AbhaActionButton";
import AbhaCardView from "../common/AbhaCardView";
// const file imports(helper methods, etc) ⇒
import {
  addAbhaAddress,
  downloadAbhaCard,
  getAbhaAddressSuggestion,
} from "../AbhaAPIHandler";
import {
  ABHA_ADDRESS_SUGGESTION,
  ABHA_ADD_ADDRESS,
  DOWNLOAD_ABHA_CARD,
} from "../AbhaAPIHandler/APIConstants";
import { PROD } from "../../common/config";

const AbhaLinkWithAddressView = (props) => {
  const { transactionID, abhaNumber, patientId } = props;
  // const declarations ⇒
  const [abhaAddressExist, setAbhaAddressExist] = useState("");
  const [disabledButton, setDisableButton] = useState(true);
  const [abhaAddressCreatedSuccfully, setAbhaAddressCreatedSuccessfully] =
    useState(false);
  const [abhaAddress, setAbhaAddress] = useState("");
  const [suggestionList, setSuggestionList] = useState([]);
  const [abhaAddressError, setAbhaAddressError] = useState(false);

  const abhaAddressChecbox = [
    {
      value: "yes",
      label: "Yes",
    },
    {
      value: "no",
      label: "No",
    },
  ];

  // life cycle methods ⇒
  useEffect(() => {
    if (abhaAddress && abhaAddressExist) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [abhaAddressExist, abhaAddress]);

  useEffect(() => {
    if (abhaAddressExist === "no") {
      let requestPayload = {
        txnid: transactionID,
      };
      getAbhaAddressSuggestionAPICall(requestPayload);
    }
  }, [abhaAddressExist]);

  // API Calls ⇒
  const addAbhaAddressAPICall = (reqPayload) => {
    const addAbhaAddressResponse = addAbhaAddress(
      ABHA_ADD_ADDRESS(patientId),
      reqPayload
    ); //Fetch API Data
    addAbhaAddressResponse.then(([res, error]) => {
      if (!error) {
        if (res?.data?.statusCode == 200) {
          setAbhaAddressCreatedSuccessfully(true);
        } else {
          setAbhaAddressError(true);
        }
      } else {
        setAbhaAddressError(true);
      }
    });
  };

  const getAbhaAddressSuggestionAPICall = (reqPayload) => {
    const abhaAddressSuggestionResponse = getAbhaAddressSuggestion(
      ABHA_ADDRESS_SUGGESTION(patientId),
      reqPayload
    ); //Fetch API Data
    abhaAddressSuggestionResponse.then(([res, error]) => {
      if (!error) {
        if (res?.data?.statusCode == 200) {
          if (res?.data?.abhaAddressList?.length > 0) {
            setSuggestionList(res?.data?.abhaAddressList);
          } else {
            setSuggestionList([]);
          }
        } else {
          setSuggestionList([]);
        }
      } else {
        setSuggestionList([]);
      }
    });
  };

  // Actions ⇒
  const handleChekbox = (e) => {
    setAbhaAddressExist(e.target.value);
  };

  const handleCreateAndLinkAbha = () => {
    let payload = {
      abha_address: abhaAddress,
      txnid: transactionID,
    };
    addAbhaAddressAPICall(payload);
  };

  const handleSelectSuggestion = (value) => {
    setAbhaAddress(value);
  };

  const handleAbhaAddressInput = (e) => {
    setAbhaAddress(e.target.value);
  };

  const handleDownloadAbha = () => {
    let reqPayload = {
      txnid: transactionID,
      type: "card",
    };
    const qrResponse = downloadAbhaCard(
      DOWNLOAD_ABHA_CARD(patientId),
      reqPayload
    ); //Fetch API Data
    qrResponse.then(([res, error]) => {
      if (!error) {
        if (res?.data?.statusCode == 200) {
          let pdf = res?.data?.content;
          const linkSource = `data:application/pdf;base64,${pdf}`;
          const downloadLink = document.createElement("a");
          const fileName = "abha_card.pdf";

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    });
  };

  // create UI ⇒
  const abhaLinkMobileHeading = () => {
    return <div className="alwav-heading">Create ABHA (Health ID) Card</div>;
  };

  const abhaAadharCreatedUI = () => {
    return (
      <div className="alwav-aadhar-abha-created-container">
        <div className="alwav-aadhar-abha-created-content">
          <div className="alwav-aadhar-abha-created-icon"></div>
          <img className="alwav-card-tick-gif" src={TickGif} alt="" />
          <div className="alwav-aadhar-abha-created-text">
            Your Aadhaar ABHA Number Is Created
          </div>
        </div>
      </div>
    );
  };

  const abhaIdUI = () => {
    return (
      <div className="alwav-abha-id-container">
        <div className="alwav-abha-id-label">Your ABHA Number is</div>
        <div className="alwav-abha-id-text">{abhaNumber}</div>
      </div>
    );
  };

  const abhaAadharCheckboxUI = () => {
    return (
      <div className="alwav-checkbox-container">
        <div className="alwav-abha-id-label">
          Do you have Existing Abha Address?
        </div>
        <div className="alwav-checkbox-input-container">
          {abhaAddressChecbox?.map((data, index) => (
            <div className="alwav-checkbox-input" key={index}>
              <input
                type="radio"
                value={data?.value}
                id={data?.value}
                checked={abhaAddressExist == data?.value ? true : false}
                onChange={handleChekbox}
              />
              <label for={data?.value} className="alwav-checkbox-input-label">
                <span>{data?.label}</span>
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const abhaLinkAbhaAddressButtonUI = () => {
    return (
      <div className="alwav-create-link-button-container">
        <AbhaActionButton
          title={
            !abhaAddressCreatedSuccfully
              ? abhaAddressExist === "yes"
                ? "Link your ABHA address"
                : "Create and Link ABHA Address"
              : "Download ABHA Card"
          }
          disabled={!abhaAddressCreatedSuccfully && disabledButton}
          handleClick={
            !abhaAddressCreatedSuccfully
              ? handleCreateAndLinkAbha
              : handleDownloadAbha
          }
        />
      </div>
    );
  };

  const abhaAddressCreatedUI = () => {
    return (
      <div className="alwav-abha-id-container">
        <div className="alwav-abha-id-label">Your ABHA Address is</div>
        <div className="alwav-abha-id-text">{abhaAddress}</div>
      </div>
    );
  };

  const abhaAddressCreateInputUI = () => {
    return (
      <div>
        {abhaAddressInputUI()}
        {abhaAddressExist == "no" &&
          suggestionList?.length > 0 &&
          abhaAddressSuggestionListUI()}
      </div>
    );
  };

  const abhaAddressSuggestionListUI = () => {
    return (
      <div className="alwav-abha-suggestion-container">
        <div className="alwav-abha-suggestion-list">
          <span className="alwav-abha-suggestion-label">Suggestion:</span>
          <div className="abha-suggestion-list-wrapper">
            {suggestionList?.map((suggestion, index) => (
              <div
                className="alwav-abha-suggestion"
                onClick={() => handleSelectSuggestion(suggestion)}
                key={index}
              >
                <span>
                  {suggestion}
                  {suggestionList.length > 1
                    ? index != suggestionList?.length - 1
                      ? ", "
                      : ""
                    : ""}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const abhaAddressInputUI = () => {
    return (
      <div className="alwav-abha-input-container">
        <div
          className={
            "alwav-abha-input-content " +
            (abhaAddressError ? "alwav-abha-input-content-error" : "")
          }
        >
          <input
            className="alwav-abha-input"
            value={abhaAddress}
            onChange={handleAbhaAddressInput}
          />
          <div className="alwav-abha-input-suffix">
            {process.env.REACT_APP_ENV !== PROD ? "@sbx" : "@abdm"}
          </div>
        </div>
        {abhaAddressError && (
          <div className="alwav-abha-input-error">
            Entered Abha address is not available
          </div>
        )}
      </div>
    );
  };

  const abhaLinkWithAddressViewUI = () => {
    return (
      <div>
        <AbhaCardView>
          {abhaLinkMobileHeading()}
          {abhaAadharCreatedUI()}
          {abhaIdUI()}
          {!abhaAddressCreatedSuccfully && abhaAadharCheckboxUI()}
          {!abhaAddressCreatedSuccfully && abhaAddressExist
            ? abhaAddressCreateInputUI()
            : null}
          {abhaAddressCreatedSuccfully ? abhaAddressCreatedUI() : null}
          {abhaLinkAbhaAddressButtonUI()}
        </AbhaCardView>
      </div>
    );
  };

  return (
    <div className="abha-link-with-address-view">
      {abhaLinkWithAddressViewUI()}
    </div>
  );
};

//export
export default AbhaLinkWithAddressView;
