//Dependies import
import React, { useRef, useState } from "react";
//assest and scss import
import "./abhaViewContainer.scss";
import ManWithQRImage from "../../assets/abhaPageAssets/Mask Group 153/Mask Group 153.png";
import PhoneImgOtp from "../../assets/abhaPageAssets/Group 29113/1.png";
import ConformAbhaPhone from "../../assets/abhaPageAssets/Group 29110/Group 29110.png";
import VerifiedPhone from "../../assets/abhaPageAssets/Group 29366/Group 29366.png";
import ForwordArrow from "../../assets/abhaPageAssets/arrow.svg";
import anthemLogo from "../../assets/anthem-logo.png";
import PlayButtonSvg from "../../assets/abhaPageAssets/Group 29123.svg";
import OldManImage from "../../assets/abhaPageAssets/Mask Group 156/Mask Group 156.png";
import ManWithCard from "../../assets/abhaPageAssets/img.png";
import QuestionMarkImg from "../../assets/abhaPageAssets/Group 29177/Group 29177.png";
import AbhaCarcImg from "../../assets/abhaPageAssets/Group 29179/Group 29179.png";
//<==
//project files import
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
  BENEFITS_LIST,
  SERVICES_POINTS,
  FAQ_QUESTIONS_LIST,
  ABHATEXTCONTANT,
  ABHAWHATCONTENT,
  ABOUTABHA,
  HEALTHACCOUNTAI,
  SERVICEABHA,
  ABHAFAQ,
  ABHATOPCONTANT,
  ABOUTABHAMOBILEVIEW,
} from "./constant";
import HomePageHeaderContainer from "../HomePageViewContainer/HomePageHeaderContainer";
import HomePageFooterContainer from "../HomePageViewContainer/HomePageFooterContainer";
import AbhaAadharValidationView from "./AbhaAadharValidationView";
import AbhaAadharOTPView from "./AbhaAadharOTPView";
import AbhaMobileLinkView from "./AbhaMobileLinkView";
import AbhaLinkWithAddressView from "./AbhaLinkWithAddressView";
import AbhaExistingCardView from "./AbhaExistingCardView";

function AbhaViewContainer() {
  //const import ==>
  const [onPlayVideo, setOnPlayVideo] = useState(false);
  const onhandlePlayVideo = () => {
    setOnPlayVideo((prevCheck) => !prevCheck);
  };
  const aboutRef = useRef(null);
  const featureRef = useRef(null);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [steps, setSteps] = useState(1);
  const [transactionID, setTransactionID] = useState("");
  const [aadharMobileNumber, setAadharMobileNumber] = useState("");
  const [aadharNumber, setAadharNumber] = useState("");
  const [patientId, setPatientId] = useState("");
  const [abhaNumber, setAbhaNumber] = useState("");
  const [enteredMobileNumber, setEnteredMobileNumber] = useState("");

  //methods ==>
  const toggleFaq = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const handleScrollToContent = (scrollTo) => {
    if (scrollTo === "feature") {
      featureRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const renderComponentBasedOnWidth = (componentLarge, componentSmall) => {
    return window.innerWidth > 768 ? componentLarge() : componentSmall();
  };

  const handleSteps = (step) => {
    setSteps(step);
  };

  const handleAadharData = (aadhar_number, transaction_id, aadharMobile) => {
    setAadharNumber(aadhar_number);
    setAadharMobileNumber(aadharMobile);
    setTransactionID(transaction_id);
    handleSteps(2);
  };

  const updateTransactionID = (transaction_id) => {
    setTransactionID(transaction_id);
  };

  const handlePatientId = (patient_id) => {
    setPatientId(patient_id);
  };

  const handleAbhaNumber = (abha_number) => {
    setAbhaNumber(abha_number);
  };

  const handleMobileNumberChange = (number) => {
    setEnteredMobileNumber(number);
  };

  //<==
  //create UI ==>
  const abhaHeadingViewUi = () => {
    return (
      <div className="abha-view-container">
        <div className="abha-heading-pera-wrapper">
          <div className="abha-heading-wrapper">{ABHATEXTCONTANT.heading}</div>
          <div className="abha-peragraph-wrapper">
            {ABHATEXTCONTANT.paragraph}
          </div>
        </div>
      </div>
    );
  };

  const abhaHeadingViewMobileUi = () => {
    return (
      <div className="abha-view-mobile-container">
        <div className="abha-heading-wrapper-mobile-view">
          {ABHATEXTCONTANT.heading}
        </div>
        <div className="abha-peragraph-wrapper-mobile-view">
          {ABHATEXTCONTANT.paragraph}
        </div>
      </div>
    );
  };

  const whatISAbhaUi = () => {
    return (
      <div className="what-is-abha-container">
        <div className="abha-gray-background-box"></div>
        <img
          className="man-with-qr-image"
          src={ManWithQRImage}
          alt="manwithQr"
        />
        <div className="abha-white-background-box">
          <div className="whats-is-abha-heading-pera-box">
            <div className="whatsis-abha-heading">
              {" "}
              {ABHAWHATCONTENT.heading}
            </div>
            <div className="whatsis-abha-peragraph">
              {ABHAWHATCONTENT.paragraphsone}
            </div>
            <div className="whatsis-abha-peragraph">
              {ABHAWHATCONTENT.paragraphsTwo}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const whatISAbhaMobileUi = () => {
    return (
      <div className="what-is-abha-container-mobileview">
        <div>
          <div className="abha-gray-background-box">
            <img src={ManWithQRImage} alt="manwithQr" />
          </div>
        </div>
        <div>
          <div className="whats-is-abha-heading-pera-box-mobileView">
            {ABHAWHATCONTENT.heading}
          </div>
          <div className="whatsis-abha-peragraph-mobile-view">
            {ABHAWHATCONTENT.paragraphsone}
          </div>
          <div className="whatsis-abha-peragraph-mobile-view">
            {ABHAWHATCONTENT.paragraphsTwo}
          </div>
        </div>
      </div>
    );
  };

  const createAbhaUi = () => {
    return (
      <div className="create-abha-ui-container">
        <div className="create-abha-heading-wrapper">
          <div>{ABOUTABHA.HEADING}</div>
          <div>{ABOUTABHA.HEADING2}</div>
        </div>
        <div className="create-abha-mobile-img-process-wrapper">
          <div className="create-abha-phone-img-text-wrapper">
            <div className="create-abha-phone-img-container">
              <img src={PhoneImgOtp} alt="phoneImage" />
            </div>
            <div className="create-abha-process-text-container">
              {ABOUTABHA.SUBTITLE1}
            </div>
          </div>
          <img src={ForwordArrow} alt="arrow" />
          <div className="create-abha-phone-img-text-wrapper">
            <div className="create-abha-phone-img-container">
              <img src={ConformAbhaPhone} alt="phoneImage" />
            </div>
            <div
              className="create-abha-process-text-container"
              style={{ textAlign: "center" }}
            >
              {ABOUTABHA.SUBTITLE2}
            </div>
          </div>
          <img src={ForwordArrow} alt="arrow" />
          <div className="create-abha-phone-img-text-wrapper">
            <div className="create-abha-phone-img-container">
              <img src={VerifiedPhone} alt="phoneImage" />
            </div>
            <div className="create-abha-process-text-container">
              {ABOUTABHA.SUBTITLE3}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const careateAbhaMobileUi = () => {
    return (
      <div className="create-abha-mobile-ui-container">
        <div className="create-abha-heading-mobile-wrapper">
          <div className="create-abha-heading-mobile-text-one">
            {" "}
            {ABOUTABHAMOBILEVIEW.HEADING}
          </div>
          <div className="create-abha-heading-mobile-text-one">
            {ABOUTABHAMOBILEVIEW.HEADING2}
          </div>
        </div>
        <div className="create-abha-mobile-img-process-wrapper-mobile-UI">
          <img src={PhoneImgOtp} alt="phoneImage" />
        </div>
        <div className="create-abha-mobile-text-mobile">
          {ABOUTABHAMOBILEVIEW.SUBTITLE1}
        </div>
        <div className="create-abha-mobile-subtext-mobile">
          {ABOUTABHAMOBILEVIEW.SUBHEADINGSUB1}
        </div>
        <div className="create-abha-mobile-img-process-wrapper-mobile-UI">
          <img
            style={{ marginLeft: -90 }}
            src={ConformAbhaPhone}
            alt="phoneImage"
          />
        </div>
        <div className="create-abha-mobile-text-mobile">
          {ABOUTABHAMOBILEVIEW.SUBTITLE2}
        </div>
        <div className="create-abha-mobile-subtext-mobile">
          {ABOUTABHAMOBILEVIEW.SUBHEADINGSUB2}
        </div>
        <div className="create-abha-mobile-img-process-wrapper-mobile-UI">
          <img src={VerifiedPhone} alt="phoneImage" />
        </div>
        <div className="create-abha-mobile-text-mobile">
          {ABOUTABHAMOBILEVIEW.SUBTITLE3}
        </div>
        <div className="create-abha-mobile-subtext-mobile">
          {ABOUTABHAMOBILEVIEW.SUBHEADINGSUB3}
        </div>
      </div>
    );
  };

  const abhaHealthAccountUi = () => {
    return (
      <div className="abha-health-account-container">
        <div className="abha-health-account-heading-peragraph">
          <div className="abha-health-account-heading">
            {HEALTHACCOUNTAI.HEALTHHEADING}
          </div>
          <div className="about-text-color">
            {HEALTHACCOUNTAI.HEALTHSUBHEADING}
          </div>
          <div className="about-text-color">
            {HEALTHACCOUNTAI.HEALTHSUBHEADING2}
          </div>
          <div className="about-text-color">
            {HEALTHACCOUNTAI.HEALTHSUBHEADING3}
          </div>
        </div>
        <div className="abha-health-account-video-container">
          <div className="health-account-videoclip">
            {!onPlayVideo && (
              <img
                onClick={onhandlePlayVideo}
                className="video-play-btn"
                src={PlayButtonSvg}
                alt="playButton"
              />
            )}
            {onPlayVideo && (
              <iframe
                className="youtube-video"
                width="560"
                height="360"
                src="https://www.youtube.com/embed/kAqyaT77AjM?si=eJBlVjxiFWDsN2gD"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            )}
          </div>
        </div>
      </div>
    );
  };

  const abhaHealthAccountMobileUi = () => {
    return (
      <div className="abha-health-account-container">
        <div className="abha-health-account-heading-peragraph-mobile">
          <div className="abha-health-account-heading-peragraph-mobile-view">
            {HEALTHACCOUNTAI.HEALTHHEADING}
          </div>
          <div>{HEALTHACCOUNTAI.HEALTHSUBHEADING}</div>
          <div>{HEALTHACCOUNTAI.HEALTHSUBHEADING2}</div>
          <div>{HEALTHACCOUNTAI.HEALTHSUBHEADING3}</div>
          <div className="vedio-icon-img">
            {!onPlayVideo && (
              <img
                onClick={onhandlePlayVideo}
                className="video-play-btn"
                src={PlayButtonSvg}
                alt="playButton"
              />
            )}
            {onPlayVideo && (
              <iframe
                className="youtube-video"
                width="560"
                height="360"
                src="https://www.youtube.com/embed/kAqyaT77AjM?si=eJBlVjxiFWDsN2gD"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            )}
          </div>
        </div>
      </div>
    );
  };

  const benifitsOfAbha = () => {
    return (
      <div className="benefits-of-abha-container">
        <div className="benefits-of-abha-image-wrapper">
          <img className="benefits-old-Man-image" src={OldManImage} alt="man" />
        </div>
        <div className="benefits-of-abha-text-wrapper">
          <div className="benefits-heading-list-wrapper">
            <div className="benefits-abha-heading">Benefits of using ABHA</div>
            <div className="list-of-benifits-box">
              {BENEFITS_LIST.map((item, key) => (
                <div key={key} className="benifit-list">
                  <div className="list-serial-no">{item.serial}</div>
                  <div className="benifit-list-title">{item.title}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const benifitsOfAbhaMobileView = () => {
    return (
      <div>
        <div className="benefits-heading-list-wrapper">
          <div>
            <div className="benefits-abha-heading">Benefits of using ABHA</div>
            <div className="list-of-benifits-box">
              {BENEFITS_LIST.map((item, key) => (
                <div key={key} className="benifit-list">
                  <div style={{ marginLeft: 20 }}>{item.serial}</div>
                  <div>{item.title}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="benefits-of-abha-image-wrapper">
          <img className="benefits-old-Man-image" src={OldManImage} alt="man" />
        </div>
      </div>
    );
  };

  const c2cAvbdmSecvicesMobileUi = () => {
    return (
      <div>
        <div style={{ padding: 20, backgroundColor: "#FAECD6" }}>
          <div className="servies-heading-mobile">{SERVICEABHA.HEADING}</div>
          <div className="servies-pera-wrapper-mobile">
            {SERVICEABHA.SUBHEADING}
          </div>
          <div className="servies-btns-wrapper-mobile">
            <div>{SERVICEABHA.SUBHEADING2}</div>
            <div className="servies-btns-wrapper-mobile">
              {SERVICES_POINTS.map((item, key) => (
                <div key={key} className="servies-point-list-mobile">
                  {item.title}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="c2c-adbm-serivce-background-sec-mobile"></div>
        <img
          className="man-with-abha-card-img-mobile"
          src={ManWithCard}
          alt="man"
        />
      </div>
    );
  };

  const c2cAvbdmSecvicesUi = () => {
    return (
      <div className="c2c-adbm-serivce-container">
        <div className="c2c-adbm-serivce-content-wrapper">
          <div className="c2c-adbm-serivce-background-first">
            <div className="servies-heading-pera-btn-wrapper">
              <div className="servies-heading">{SERVICEABHA.HEADING}</div>
              <div className="servies-pera-wrapper">
                {SERVICEABHA.SUBHEADING}
              </div>
              <div className="servies-btns-wrapper">
                <div>{SERVICEABHA.SUBHEADING2}</div>
                <div className="servies-points-wrapper">
                  {SERVICES_POINTS.map((item, key) => (
                    <div key={key} className="servies-point-list">
                      {item.title}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="c2c-adbm-serivce-background-sec"></div>
          <img className="man-with-abha-card-img" src={ManWithCard} alt="man" />
        </div>
      </div>
    );
  };

  const createFAQUI = () => {
    return (
      <div className="faq-abha-integration-container">
        <div className="faq-abha-integration-content-wrapper">
          <div className="faq-abha-integration-heading-text-wrapper">
            <div className="faq-abha-integration-heading">
              {ABHAFAQ.FAQHEADING}
            </div>
            <div className="faq-abha-integration-peragraph">
              {ABHAFAQ.FAQSUBHEADING}
            </div>
            <div className="faq-abha-integration-questions-wrapper">
              {FAQ_QUESTIONS_LIST.map((item, index) => (
                <div key={index}>
                  <div className="faq-question-list-box">
                    <div>{item.question}</div>
                    <div onClick={() => toggleFaq(index)}>
                      {expandedIndex === index ? (
                        <div className="circle-color">
                          <RemoveCircleOutlineIcon />
                        </div>
                      ) : (
                        <div className="circle-color">
                          <ControlPointIcon />
                        </div>
                      )}
                    </div>
                  </div>
                  {expandedIndex === index && (
                    <div className="faq-answer-list-box">{item.answer}</div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="faq-abha-integration-images-wrapper"></div>
          <img className="faq-abha-abhacard" src={AbhaCarcImg} alt="abhsCard" />
          <img
            className="faq-abha-question-mark-img"
            src={QuestionMarkImg}
            alt="questionMark"
          />
        </div>
      </div>
    );
  };

  const AbhaCardView = () => {
    return (
      <div>
        {steps === 1 ? (
          <AbhaAadharValidationView
            handleSteps={handleSteps}
            handleAadharData={handleAadharData}
          />
        ) : steps === 2 ? (
          <AbhaAadharOTPView
            aadharNumber={aadharNumber}
            transactionID={transactionID}
            aadharMobileNumber={aadharMobileNumber}
            enteredMobileNumber={enteredMobileNumber}
            handleSteps={handleSteps}
            updateTransactionID={updateTransactionID}
            handlePatientId={handlePatientId}
            handleMobileNumberChange={handleMobileNumberChange}
            handleAbhaNumber={handleAbhaNumber}
          />
        ) : steps === 3 ? (
          <AbhaMobileLinkView
            aadharNumber={aadharNumber}
            transactionID={transactionID}
            aadharMobileNumber={aadharMobileNumber}
            enteredMobileNumber={enteredMobileNumber}
            patientId={patientId}
            handleSteps={handleSteps}
            handleAbhaNumber={handleAbhaNumber}
            handlePatientId={handlePatientId}
            handleMobileNumberChange={handleMobileNumberChange}
          />
        ) : steps === 4 ? (
          <AbhaLinkWithAddressView
            aadharNumber={aadharNumber}
            transactionID={transactionID}
            aadharMobileNumber={aadharMobileNumber}
            patientId={patientId}
            abhaNumber={abhaNumber}
            handleSteps={handleSteps}
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  const createFAQMobileUI = () => {
    return (
      <div className="faq-abha-integration-container">
        <div className="faq-abha-integration-content-wrapper">
          <div>
            <div className="faq-title-header-view"> {ABHAFAQ.FAQHEADING}</div>
            <div className="faq-subtitle-view">{ABHAFAQ.FAQSUBHEADING}</div>
            <div>
              {FAQ_QUESTIONS_LIST.map((item, index) => (
                <div key={index}>
                  <div className="faq-question-list-box">
                    <div>{item.question}</div>
                    <div onClick={() => toggleFaq(index)}>
                      {expandedIndex === index ? (
                        <div className="circle-color">
                          <RemoveCircleOutlineIcon />
                        </div>
                      ) : (
                        <div className="circle-color">
                          <ControlPointIcon />
                        </div>
                      )}
                    </div>
                  </div>
                  {expandedIndex === index && (
                    <div className="faq-answer-list-box">{item.answer}</div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const createTopAbhaHeaderMobileView = () => {
    return (
      <div className="abha-view-header-containt-mobile-view">
        <HomePageHeaderContainer
          handleScrollToContent={handleScrollToContent}
          showDetails={true}
        />
        <div>{AbhaCardView()}</div>
        <div className="abha-view-header-heading-mobile">
          <div className="abha-view-header-title-containt-mobile">
            {ABHATOPCONTANT.TOPHEADING}
            <div className="abha-view-header-subtitle-containt-mobile">
              {ABHATOPCONTANT.TOPHEADING2}
            </div>
            <div className="abha-view-header-subtitle-containt-sec-mobile">
              <span>{ABHATOPCONTANT.TOPHEADING3} </span>
              <span className="abha-view-header-width">
                {ABHATOPCONTANT.TOPHEADING4}
              </span>
              <br />
              {ABHATOPCONTANT.TOPHEADING5}
            </div>
          </div>
        </div>
        <div className="abha-view-header-text-mobile">
          The Indian government’s Ayushman Bharat Health Account (ABHA) <br />{" "}
          or Health ID program is part of the Ayushman Bharat Digital Mission{" "}
          <br /> (ABDM), which aims to create a centralized database of all the{" "}
          <br />
          health-related information for Indian people.
        </div>
        <div className="abha-view-header-logo">
          <img src={anthemLogo} alt="" />
        </div>
      </div>
    );
  };

  const createTopAbhaHeaderView = () => {
    return (
      <div className="abha-view-header-containt">
        <HomePageHeaderContainer
          handleScrollToContent={handleScrollToContent}
          showDetails={true}
        />
        <div className="abha-view-header-heading">
          <div>
            <div className="abha-view-header-title-containt">
              {ABHATOPCONTANT.TOPHEADING}
              <div className="abha-view-header-subtitle-containt">
                {ABHATOPCONTANT.TOPHEADING2}
              </div>
              <div className="abha-view-header-subtitle-containt-sec">
                <span> {ABHATOPCONTANT.TOPHEADING3} </span>
                <span className="abha-view-header-width ">
                  {" "}
                  {ABHATOPCONTANT.TOPHEADING4}
                </span>
                <br />
                {ABHATOPCONTANT.TOPHEADING5}
              </div>
            </div>
            <div className="abha-view-header-text">
              The Indian government’s Ayushman Bharat Health Account (ABHA){" "}
              <br /> or Health ID program is part of the Ayushman Bharat Digital
              Mission <br /> (ABDM), which aims to create a centralized database
              of all the <br />
              health-related information for Indian people.
            </div>
            <div className="abha-view-header-logo">
              <img src={anthemLogo} alt="" />
            </div>
          </div>
          <div>{AbhaCardView()}</div>
        </div>
      </div>
    );
  };

  const abhaViewContainerUI = () => {
    return (
      <div>
        {renderComponentBasedOnWidth(
          createTopAbhaHeaderView,
          createTopAbhaHeaderMobileView
        )}
        {renderComponentBasedOnWidth(
          abhaHeadingViewUi,
          abhaHeadingViewMobileUi
        )}
        {renderComponentBasedOnWidth(whatISAbhaUi, whatISAbhaMobileUi)}
        {renderComponentBasedOnWidth(createAbhaUi, careateAbhaMobileUi)}
        {renderComponentBasedOnWidth(
          abhaHealthAccountUi,
          abhaHealthAccountMobileUi
        )}
        {renderComponentBasedOnWidth(benifitsOfAbha, benifitsOfAbhaMobileView)}
        {renderComponentBasedOnWidth(
          c2cAvbdmSecvicesUi,
          c2cAvbdmSecvicesMobileUi
        )}
        {renderComponentBasedOnWidth(createFAQUI, createFAQMobileUI)}
        <HomePageFooterContainer
          handleScrollToContent={handleScrollToContent}
        />
      </div>
    );
  };
  //<==

  return (
    <>
      <div className="abha-main-containt-view">
        {steps === 5 ? (
          <AbhaExistingCardView
            patientId={patientId}
            transactionID={transactionID}
          />
        ) : (
          abhaViewContainerUI()
        )}
      </div>
    </>
  );
}

export default AbhaViewContainer;
