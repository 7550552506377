import React, { useEffect } from "react";
import "./Loader.scss";
import LoaderLottie from "../../../assets/loader_lottie.json";
import lottie from "lottie-web";

export default function () {
  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#loader-logo"),
      animationData: LoaderLottie,
      renderer: "svg",
      loop: true,
      autoplay: true,
    });
  }, []);

  return (
    <div className={"loader__root"}>
      <div className="loader-container">
        <div id="loader-logo"></div>
      </div>
    </div>
  );
}
