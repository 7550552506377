import React, { useEffect, useState } from "react";
import moment from "moment";
import classes from "../DoctorProfile/DoctorProfile.module.css";
import afterLoginApiAxios from "../../Util/API Calls/afterLogin";
import { LOCALSTORAGEKEY } from "../../Util/Helper/localStorageUtils";
import { C2CConsole } from "../../Util/Helper/C2CConsole";

const DateSelector = ({
  onSelect,
  consultType,
  selectedDate,
  fromCalender,
  calenderDate,
  track,
  selectedDateMeta,
  followUpEndDate,
  dashboardSelectedDate,
}) => {
  const [dateArrayState, setDateArray] = useState([]);
  useEffect(() => {
    let clinicId = localStorage.getItem(LOCALSTORAGEKEY.CLINIC_ID);
    let userId = localStorage.getItem(LOCALSTORAGEKEY.USER_ID);
    if (calenderDate && consultType === 1) {
      afterLoginApiAxios
        .post(
          "patient/patient-follow-up-slots-by-doctor-id",
          {
            patient_id: userId,
            patient_name: localStorage.getItem("patient_name"),
            patient_dob: localStorage.getItem("patient_dob"),
            patient_account_id: localStorage.getItem("patient_account_id"),
            consultation_type: localStorage.getItem(
              "followup_consultation_type"
            ),
            doctor_id: JSON.parse(localStorage.getItem("doctor"))?.doctor_id,
            date: moment(calenderDate).format("YYYY-M-D"),
            ...(clinicId && { clinic_id: clinicId }),
          },
          {
            headers: {
              authorization: localStorage.getItem("id_token"),
            },
          }
        )
        .then((res) => {
          if (res?.data?.follow_up_end_date) {
            handletrack(res.data.follow_up_end_date);
          } else {
            handletrack();
          }
        })
        .catch((err) => C2CConsole.error(err));
    } else {
      handletrack();
    }
  }, [track]);

  const handletrack = (followUpEndDates) => {
    // Makes 7 Day Array
    const dateArray = [];
    if (consultType === 1) {
      //Follow Up
      if (fromCalender && !moment(calenderDate).isSame(new Date(), "day")) {
        if (followUpEndDates) {
          const todayDate = new Date(moment(new Date()).format("YYYY-MM-DD"));
          const tillFllowUpDate = new Date(
            moment(new Date(followUpEndDates)).format("YYYY-MM-DD")
          );
          const diffTime = Math.abs(tillFllowUpDate - todayDate);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          for (let i = 0; i < diffDays + 1; ++i) {
            dateArray.push({
              id: i,
              date: moment().add(i, "day").format("D MMM"),
              day: moment().add(i, "day").format("dddd"),
              meta: moment().add(i, "day"),
            });
          }
          onSelect(
            dateArray[
              moment(moment(calenderDate).format("YYYY MM DD")).diff(
                moment(moment(new Date()).format("YYYY MM DD")),
                "day"
              )
            ]
          );
          setDateArray(dateArray);
        } else {
          if (
            moment(moment(calenderDate).format("YYYY MM DD")).diff(
              moment(moment(new Date()).format("YYYY MM DD")),
              "day"
            ) === 1
          ) {
            for (let i = 0; i < 7; ++i) {
              dateArray.push({
                id: i,
                date: moment().add(i, "day").format("D MMM"),
                day: moment().add(i, "day").format("dddd"),
                meta: moment().add(i, "day"),
              });
            }
            onSelect(dateArray[1]);
            setDateArray(dateArray);
          } else if (
            moment(moment(calenderDate).format("YYYY MM DD")).diff(
              moment(moment(new Date()).format("YYYY MM DD")),
              "day"
            ) === 2
          ) {
            for (let i = 0; i < 7; ++i) {
              dateArray.push({
                id: i,
                date: moment().add(i, "day").format("D MMM"),
                day: moment().add(i, "day").format("dddd"),
                meta: moment().add(i, "day"),
              });
            }
            onSelect(dateArray[2]);
            setDateArray(dateArray);
          } else if (
            moment(moment(calenderDate).format("YYYY MM DD")).diff(
              moment(moment(new Date()).format("YYYY MM DD")),
              "day"
            ) === 3
          ) {
            for (let i = 0; i < 7; ++i) {
              dateArray.push({
                id: i,
                date: moment().add(i, "day").format("D MMM"),
                day: moment().add(i, "day").format("dddd"),
                meta: moment().add(i, "day"),
              });
            }
            onSelect(dateArray[3]);
            setDateArray(dateArray);
          } else if (
            moment(
              moment(new Date(followUpEndDates)).format("YYYY MM DD")
            ).diff(moment(moment(calenderDate).format("YYYY MM DD")), "day") ===
            0
          ) {
            let adjustedDate = new Date(followUpEndDates);
            for (let i = 6; i >= 0; i--) {
              dateArray.push({
                id: i,
                date: moment(adjustedDate).subtract(i, "day").format("D MMM"),
                day: moment(adjustedDate).subtract(i, "day").format("dddd"),
                meta: moment(adjustedDate).subtract(i, "day"),
              });
            }
            onSelect(dateArray[dateArray.length - 1]);
            setDateArray(dateArray);
          } else if (
            moment(
              moment(new Date(followUpEndDates)).format("YYYY MM DD")
            ).diff(moment(moment(calenderDate).format("YYYY MM DD")), "day") ===
            1
          ) {
            let adjustedDate = new Date(followUpEndDates);
            for (let i = 6; i >= 0; i--) {
              dateArray.push({
                id: i,
                date: moment(adjustedDate).subtract(i, "day").format("D MMM"),
                day: moment(adjustedDate).subtract(i, "day").format("dddd"),
                meta: moment(adjustedDate).subtract(i, "day"),
              });
            }
            onSelect(dateArray[dateArray.length - 2]);
            setDateArray(dateArray);
          } else if (
            moment(
              moment(new Date(followUpEndDates)).format("YYYY MM DD")
            ).diff(moment(moment(calenderDate).format("YYYY MM DD")), "day") ===
            2
          ) {
            let adjustedDate = new Date(followUpEndDates);
            for (let i = 6; i >= 0; i--) {
              dateArray.push({
                id: i,
                date: moment(adjustedDate).subtract(i, "day").format("D MMM"),
                day: moment(adjustedDate).subtract(i, "day").format("dddd"),
                meta: moment(adjustedDate).subtract(i, "day"),
              });
            }
            onSelect(dateArray[dateArray.length - 3]);
            setDateArray(dateArray);
          } else {
            for (let i = 3; i >= 1; i--) {
              dateArray.push({
                id: i,
                date: moment(calenderDate).subtract(i, "days").format("D MMM"),
                day: moment(calenderDate).subtract(i, "days").format("dddd"),
                meta: moment(calenderDate).subtract(i, "days"),
              });
            }
            dateArray.push({
              id: 0,
              date: moment(calenderDate).format("D MMM"),
              day: moment(calenderDate).format("dddd"),
              meta: moment(calenderDate),
            });
            for (let i = 1; i <= 3; i++) {
              dateArray.push({
                id: i + 3,
                date: moment(calenderDate).add(i, "days").format("D MMM"),
                day: moment(calenderDate).add(i, "days").format("dddd"),
                meta: moment(calenderDate).add(i, "days"),
              });
            }
            onSelect(dateArray[3]);
            setDateArray(dateArray);
          }
        }
      } else {
        if (followUpEndDates) {
          const todayDate = new Date(moment(new Date()).format("YYYY-MM-DD"));
          const tillFllowUpDate = new Date(
            moment(new Date(followUpEndDates)).format("YYYY-MM-DD")
          );
          const diffTime = Math.abs(tillFllowUpDate - todayDate);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
          for (let i = 0; i < diffDays + 1; ++i) {
            dateArray.push({
              id: i,
              date: moment().add(i, "day").format("D MMM"),
              day: moment().add(i, "day").format("dddd"),
              meta: moment().add(i, "day"),
            });
          }
          onSelect(dateArray[0]);
          setDateArray(dateArray);
        } else {
          for (let i = 0; i < 7; ++i) {
            dateArray.push({
              id: i,
              date: moment().add(i, "day").format("D MMM"),
              day: moment().add(i, "day").format("dddd"),
              meta: moment().add(i, "day"),
            });
          }
          onSelect(dateArray[0]);
          setDateArray(dateArray);
        }
      }
    } else {
      //Normal consultation
      if (fromCalender && !moment(calenderDate).isSame(new Date(), "day")) {
        if (
          moment(moment(calenderDate).format("YYYY MM DD")).diff(
            moment(moment(new Date()).format("YYYY MM DD")),
            "day"
          ) === 1
        ) {
          for (let i = 0; i < 7; ++i) {
            dateArray.push({
              id: i,
              date: moment().add(i, "day").format("D MMM"),
              day: moment().add(i, "day").format("dddd"),
              meta: moment().add(i, "day"),
            });
          }
          onSelect(dateArray[1]);
          setDateArray(dateArray);
        } else if (
          moment(moment(calenderDate).format("YYYY MM DD")).diff(
            moment(moment(new Date()).format("YYYY MM DD")),
            "day"
          ) === 2
        ) {
          for (let i = 0; i < 7; ++i) {
            dateArray.push({
              id: i,
              date: moment().add(i, "day").format("D MMM"),
              day: moment().add(i, "day").format("dddd"),
              meta: moment().add(i, "day"),
            });
          }
          onSelect(dateArray[2]);
          setDateArray(dateArray);
        } else if (
          moment(moment(calenderDate).format("YYYY MM DD")).diff(
            moment(moment(new Date()).format("YYYY MM DD")),
            "day"
          ) === 3
        ) {
          for (let i = 0; i < 7; ++i) {
            dateArray.push({
              id: i,
              date: moment().add(i, "day").format("D MMM"),
              day: moment().add(i, "day").format("dddd"),
              meta: moment().add(i, "day"),
            });
          }
          onSelect(dateArray[3]);
          setDateArray(dateArray);
        } else if (
          moment(moment(calenderDate).format("YYYY MM DD")).diff(
            moment(moment(new Date()).format("YYYY MM DD")),
            "day"
          ) === 90
        ) {
          for (let i = 6; i >= 0; i--) {
            dateArray.push({
              id: i,
              date: moment(calenderDate).subtract(i, "days").format("D MMM"),
              day: moment(calenderDate).subtract(i, "days").format("dddd"),
              meta: moment(calenderDate).subtract(i, "days"),
            });
          }
          onSelect(dateArray[dateArray.length - 1]);
          setDateArray(dateArray);
        } else if (
          moment(moment(calenderDate).format("YYYY MM DD")).diff(
            moment(moment(new Date()).format("YYYY MM DD")),
            "day"
          ) === 89
        ) {
          let adjustedDate = moment(calenderDate).add(1, "days")._d;
          for (let i = 6; i >= 0; i--) {
            dateArray.push({
              id: i,
              date: moment(adjustedDate).subtract(i, "days").format("D MMM"),
              day: moment(adjustedDate).subtract(i, "days").format("dddd"),
              meta: moment(adjustedDate).subtract(i, "days"),
            });
          }
          onSelect(dateArray[dateArray.length - 2]);
          setDateArray(dateArray);
        } else if (
          moment(moment(calenderDate).format("YYYY MM DD")).diff(
            moment(moment(new Date()).format("YYYY MM DD")),
            "day"
          ) === 88
        ) {
          let adjustedDate = moment(calenderDate).add(2, "days")._d;
          for (let i = 6; i >= 0; i--) {
            dateArray.push({
              id: i,
              date: moment(adjustedDate).subtract(i, "days").format("D MMM"),
              day: moment(adjustedDate).subtract(i, "days").format("dddd"),
              meta: moment(adjustedDate).subtract(i, "days"),
            });
          }
          onSelect(dateArray[dateArray.length - 3]);
          setDateArray(dateArray);
        } else {
          for (let i = 3; i >= 1; i--) {
            dateArray.push({
              id: i,
              date: moment(calenderDate).subtract(i, "days").format("D MMM"),
              day: moment(calenderDate).subtract(i, "days").format("dddd"),
              meta: moment(calenderDate).subtract(i, "days"),
            });
          }
          dateArray.push({
            id: 0,
            date: moment(calenderDate).format("D MMM"),
            day: moment(calenderDate).format("dddd"),
            meta: moment(calenderDate),
          });
          for (let i = 1; i <= 3; i++) {
            dateArray.push({
              id: i + 3,
              date: moment(calenderDate).add(i, "days").format("D MMM"),
              day: moment(calenderDate).add(i, "days").format("dddd"),
              meta: moment(calenderDate).add(i, "days"),
            });
          }
          onSelect(dateArray[3]);
          setDateArray(dateArray);
        }
      } else {
        if (
          dashboardSelectedDate &&
          moment(dashboardSelectedDate)?.isSameOrAfter(
            moment().format("YYYY-MM-DD")
          )
        ) {
          for (let i = 0; i < 7; ++i) {
            dateArray.push({
              id: i,
              date: moment(dashboardSelectedDate).add(i, "day").format("D MMM"),
              day: moment(dashboardSelectedDate).add(i, "day").format("dddd"),
              meta: moment(dashboardSelectedDate).add(i, "day"),
            });
          }
          onSelect(dateArray[0]);
          setDateArray(dateArray);
        } else {
          for (let i = 0; i < 7; ++i) {
            dateArray.push({
              id: i,
              date: moment().add(i, "day").format("D MMM"),
              day: moment().add(i, "day").format("dddd"),
              meta: moment().add(i, "day"),
            });
          }
          onSelect(dateArray[0]);
          setDateArray(dateArray);
        }
      }
    }
  };
  const isAfterOneYear = (date) => {
    const given = moment(date);
    const addOneYear = moment().add(12, "months");
    if (addOneYear.isAfter(given)) {
      return false;
    }
    return true;
  };
  return (
    <div className={"scrtabs-tab-container w-100 " + classes.datePicker}>
      <div className="scrtabs-tab-scroll-arrow scrtabs-tab-scroll-arrow-left">
        <span className="fa fa-angle-left" />
      </div>
      <div className="scrtabs-tabs-fixed-container">
        <div className="scrtabs-tabs-movable-container divider-b">
          <ul className="nav nav-tabs" role="tablist">
            {dateArrayState.map((date, i) => (
              <li
                role={"presentation"}
                key={date.id}
                style={{
                  background: "transparent",
                  pointerEvents:
                    (moment(new Date(followUpEndDate))?.diff(
                      moment(date?.meta._d),
                      "days"
                    ) < 0 ||
                      isAfterOneYear(date?.meta._d)) &&
                    "none",
                }}
              >
                <a
                  href="#"
                  role="tab"
                  onClick={() => onSelect(date)}
                  data-toggle="tab"
                >
                  <h4
                    style={{
                      color:
                        selectedDate.id === date.id
                          ? "#1B2027"
                          : moment(new Date(followUpEndDate))?.diff(
                              moment(date?.meta._d),
                              "days"
                            ) < 0
                          ? "#d1d3d6a1"
                          : "",
                      fontWeight: selectedDate.id === date.id ? "600" : "500",
                      borderBottom:
                        selectedDate.id === date.id ? "2px solid #f969a4" : "0",
                    }}
                  >
                    {moment(moment(date.meta._d).format("YYYY MM DD")).diff(
                      moment(moment(new Date()).format("YYYY MM DD")),
                      "day"
                    ) === 0
                      ? "Today"
                      : moment(moment(date.meta._d).format("YYYY MM DD")).diff(
                          moment(moment(new Date()).format("YYYY MM DD")),
                          "day"
                        ) === 1
                      ? "Tomorrow"
                      : date.date}
                    <span
                      style={{ textTransform: "capitalize", fontWeight: "100" }}
                    >
                      {date.day}
                    </span>
                  </h4>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DateSelector;
